import React from 'react';

import UserContextProvider from './UserContext';
import CartContextProvider from './CartContext';

function Providers({ children }) {
  return (
    <UserContextProvider>
      <CartContextProvider>{children}</CartContextProvider>
    </UserContextProvider>
  );
}

export default Providers;
