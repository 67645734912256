/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useEffect, createContext, useState } from 'react';
import { getUser, signout as userSignout } from '../api/user';



interface UserContextType {
  user: User | null;
  email: string | null;
  setEmail: React.Dispatch<React.SetStateAction<string | null>>;
  loading: boolean;
  signout: (args?: any) => Promise<void>;
  refetchUser: () => Promise<void | User>;
  dashboardAccess: boolean;
  tooYoung: boolean,
  setTooYoung: React.Dispatch<React.SetStateAction<boolean>>,
  error: boolean,
}

const UserContext = createContext<UserContextType | undefined>(undefined);

function UserContextProvider({ children }) {
  const [tooYoung, setTooYoung] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>(null);
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [email, setEmail] = useState<string | null>(null); // email is used in checkout without account

  const fetchUser = async () => {
    setError(false);
    setLoading(true);
    try {
      const { data } = await getUser();
      setUser(data);
    } catch (error) {
      setError(error.message);
      setUser(null);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const signout = async () => {
    await userSignout();
    setUser(null);
  };

  return (
    <UserContext.Provider
      value={{
        user,
        email, // only client-side email
        setEmail, // only client-side email
        loading,
        refetchUser: fetchUser,
        signout,
        error,
        dashboardAccess: !!(user && user.permissions && user.permissions.indexOf('ADMIN') !== -1),
        tooYoung,
        setTooYoung,
      }}

    >
    {children}
    </UserContext.Provider>
  );
}

function useUser() {
  const context = useContext(UserContext);
  if (context === undefined) throw new Error('useUser must be used within a UserProvider');

  return context;
}

export { useUser, UserContext };
export default UserContextProvider;
