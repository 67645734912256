import React, { useState, useEffect } from 'react';
import { Button, Container, Row, Col } from 'reactstrap';
import Link from 'next/link';

function CookieConfirmation() {
  const [hidden, setHidden] = useState(true);

  useEffect(() => {
    if (window && window.localStorage) {
      const cookiesAccepted = window.localStorage.getItem('cookiesAccepted');
      if (cookiesAccepted && cookiesAccepted === 'true') {
        setHidden(true);
      } else setHidden(false);
    }
  }, []);

  const cookieConfirmationHandler = () => {
    window.localStorage.setItem('cookiesAccepted', 'true');
    setHidden(true);
  };

  if (hidden) return null;

  return (
    <div id="cookieConfirmation" className="position-fixed bg-white w-100 py-2 border-top">
      <Container className="d-flex justify-content-between">
        <Row>
          <Col md="8">
            <p className="text-muted mb-0">
              Deze site maakt gebruik van cookies om je gebruikservaring te verbeteren. Door verder
              te surfen stem je in met het gebruik van cookies.
            </p>
          </Col>
          <Col md="4" className="cookieButtons d-flex justify-content-end align-items-center">
            <Link href="/privacy-policy" className="d-inline-block me-3 text-muted">
              Meer informatie
            </Link>
            <Button
              className="d-inline-block"
              color="primary"
              outline
              size="sm"
              onClick={cookieConfirmationHandler}
            >
              Cookies toestaan
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default CookieConfirmation;
