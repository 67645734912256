const dev = process.env.NODE_ENV === 'development';
// const dev = false;

// https://developers.google.com/tag-manager/enhanced-ecommerce#purchases
const sendEcommerceEvent = (ecommerce, eventName) => {
  const dataLayer = window && window.dataLayer || [];
  if (!dev) {
    dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    const data = { ecommerce };
    if (eventName) data.event = eventName;
    dataLayer.push(data);
  }
};

// log the pageview with their URL
const pageview = (url) => {
  if (window && window.gtag && !dev) {
    window.gtag('config', process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS, {
      page_path: url,
    });
  }
};

// log specific events happening.
// https://developers.google.com/analytics/devguides/collection/analyticsjs/enhanced-ecommerce#product-data
const event = ({ action, params }) => {
  if (window && window.gtag && !dev) {
    window.gtag('event', action, params);
  }
};

export { sendEcommerceEvent, pageview, event };

// dataLayer.push({
//   event: 'purchase',
//   ecommerce: {
//     purchase: {
//       transaction_id: _id.toString(),
//       affiliation: 'Webshop',
//       revenue: (totalAmount / 100).toString(), // Total transaction value (incl. tax and shipping)
//       tax: '0.00',
//       shipping: (shippingCost / 100).toString(),
//       coupon: coupon || null,
//       items: items.map((item) => ({
//         item_name: item.product.name,
//         item_id: item.product._id.toString(),
//         item_price: item.product.pricing.sellingPrice.toString(),
//         item_category: item.product.category,
//         item_quantity: item.quantity,
//       })),
//     },
//   },
// });
