import React from 'react';

function Paypal() {
  return (
    <svg
      width="48px"
      height="47px"
      viewBox="0 0 48 47"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Paypal logo</title>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Artboard" fillRule="nonzero">
          <g id="g26-3">
            <g id="g14-6" transform="translate(0.028804, 33.699448)">
              <path
                d="M31.1589433,0.109072898 L27.5720777,0.109072898 C27.3275419,0.109072898 27.117721,0.289539687 27.079943,0.533943281 L25.6295751,9.82282672 C25.6009864,10.0058716 25.7413775,10.1713854 25.9251622,10.1713854 L27.7650517,10.1713854 C27.9360736,10.1713854 28.0825909,10.0455743 28.1091376,9.87438866 L28.5211217,7.2411204 C28.5588997,6.99671681 28.7676996,6.81625002 29.0132564,6.81625002 L30.1481272,6.81625002 C32.5107822,6.81625002 33.8738524,5.66177819 34.2301906,3.37294368 C34.3904918,2.37212643 34.2373378,1.58529123 33.7727708,1.03460972 C33.2632787,0.428756924 32.3586493,0.109072898 31.1589433,0.109072898 L31.1589433,0.109072898 Z M31.5729695,3.50133291 C31.3769325,4.80172503 30.3936841,4.80172503 29.442598,4.80172503 L28.9009435,4.80172503 L29.2807653,2.37418891 C29.3037384,2.22723738 29.4288142,2.11895731 29.575842,2.11895731 L29.8234409,2.11895731 C30.4712821,2.11895731 31.0828769,2.11895731 31.3978635,2.49175013 C31.5867534,2.71449771 31.6444414,3.04449413 31.5729695,3.50133291 L31.5729695,3.50133291 Z"
                id="path2-0"
                fill="#009CDE"
              />
              <path
                d="M5.57814896,0.109072898 L1.99179387,0.109072898 C1.74623703,0.109072898 1.53743714,0.289539687 1.49914866,0.533943281 L0.0487807133,9.82282672 C0.0201919753,10.0058716 0.1605831,10.1713854 0.344367844,10.1713854 L2.05662905,10.1713854 C2.30167537,10.1713854 2.51047526,9.99143427 2.54876375,9.74703067 L2.94032736,7.2411204 C2.97861585,6.99671681 3.18741574,6.81625002 3.43246206,6.81625002 L4.56733286,6.81625002 C6.92947734,6.81625002 8.29305804,5.66177819 8.64990675,3.37294368 C8.81020789,2.37212643 8.65603291,1.58529123 8.19248695,1.03460972 C7.68197377,0.428756924 6.77785493,0.109072898 5.57814896,0.109072898 L5.57814896,0.109072898 Z M5.99217514,3.50133291 C5.79613808,4.80172503 4.8128897,4.80172503 3.86180365,4.80172503 L3.32065968,4.80172503 L3.69997097,2.37418891 C3.72294406,2.22723738 3.84853031,2.11895731 3.9955581,2.11895731 L4.24366751,2.11895731 C4.89099822,2.11895731 5.50259301,2.11895731 5.81757964,2.49175013 C6.005959,2.71449771 6.06313648,3.04449413 5.99217514,3.50133291 L5.99217514,3.50133291 Z"
                id="path4-1"
                fill="#003087"
              />
              <path
                d="M16.2979047,3.46008336 L14.5810489,3.46008336 C14.4335106,3.46008336 14.3084348,3.56784781 14.2854617,3.71479934 L14.2104163,4.19948158 L14.0899352,4.02365536 C13.7182816,3.47813004 12.8892082,3.29663201 12.0616663,3.29663201 C10.1645994,3.29663201 8.54372001,4.74861623 8.22822287,6.78531285 C8.06383762,7.80159869 8.29714215,8.77251001 8.86738537,9.45054952 C9.39117189,10.0734178 10.1395842,10.3322587 11.0304297,10.3322587 C12.5594167,10.3322587 13.4078896,9.34020697 13.4078896,9.34020697 L13.3313126,9.8223111 C13.3027239,10.005356 13.4426045,10.1713854 13.6263892,10.1713854 L15.1732442,10.1713854 C15.4182905,10.1713854 15.6270904,9.99143427 15.6658894,9.74754629 L16.5934918,3.80915769 C16.6220806,3.62508157 16.4816894,3.46008336 16.2979047,3.46008336 Z M13.9046189,6.83584355 C13.7387021,7.82737966 12.959659,8.49201306 11.9662004,8.49201306 C11.4679395,8.49201306 11.0692287,8.33010857 10.8129511,8.02434627 C10.5587155,7.72013082 10.4632496,7.28752615 10.5439106,6.80593763 C10.6985961,5.82368268 11.4904021,5.13687764 12.4690558,5.13687764 C12.9565959,5.13687764 13.3522436,5.29981337 13.6141369,5.60970063 C13.8765407,5.92113474 13.9801749,6.35683313 13.9046189,6.83584355 Z"
                id="path6-8"
                fill="#003087"
              />
              <path
                d="M41.8786991,3.46008336 L40.1618432,3.46008336 C40.0148155,3.46008336 39.8897397,3.56784781 39.8667666,3.71479934 L39.7912107,4.19948158 L39.6712401,4.02365536 C39.299076,3.47813004 38.4705131,3.29663201 37.6429712,3.29663201 C35.7448832,3.29663201 34.1240039,4.74861623 33.8090172,6.78531285 C33.6451425,7.80159869 33.8779365,8.77251001 34.4481797,9.45054952 C34.9719663,10.0734178 35.7203786,10.3322587 36.6112241,10.3322587 C38.1402111,10.3322587 38.988684,9.34020697 38.988684,9.34020697 L38.912107,9.8223111 C38.8830077,10.005356 39.0239094,10.1713854 39.2071836,10.1713854 L40.7540385,10.1713854 C40.9985743,10.1713854 41.2078848,9.99143427 41.2461732,9.74754629 L42.1742862,3.80915769 C42.2028749,3.62508157 42.0624838,3.46008336 41.8786991,3.46008336 L41.8786991,3.46008336 Z M39.4854133,6.83584355 C39.3194965,7.82737966 38.5404534,8.49201306 37.5469947,8.49201306 C37.0487339,8.49201306 36.6500231,8.33010857 36.393235,8.02434627 C36.1400204,7.72013082 36.0435334,7.28752615 36.124705,6.80593763 C36.279901,5.82368268 37.0706859,5.13687764 38.0493397,5.13687764 C38.5373903,5.13687764 38.933038,5.29981337 39.1944208,5.60970063 C39.4573351,5.92113474 39.5609692,6.35683313 39.4854133,6.83584355 L39.4854133,6.83584355 Z"
                id="path8-2"
                fill="#009CDE"
              />
              <path
                d="M25.4452798,3.46008336 L23.7187243,3.46008336 C23.5538285,3.46008336 23.399143,3.54258246 23.3062296,3.68025284 L20.9251961,7.2225581 L19.9154011,3.81895446 C19.852608,3.60548803 19.6581024,3.46008336 19.4380713,3.46008336 L17.7411254,3.46008336 C17.5364097,3.46008336 17.3924449,3.66375302 17.4577906,3.85917277 L19.3589417,9.49437717 L17.5711246,12.0420526 C17.4312439,12.2421129 17.5731666,12.5184849 17.8156604,12.5184849 L19.5406844,12.5184849 C19.7035381,12.5184849 19.8566921,12.4375327 19.950116,12.3019248 L25.6913472,3.93393759 C25.8281647,3.73336164 25.6862421,3.46008336 25.4452798,3.46008336 L25.4452798,3.46008336 Z"
                id="path10-6"
                fill="#003087"
              />
              <path
                d="M43.9028838,0.36378888 L42.4310743,9.8223111 C42.4019751,10.005356 42.5423662,10.1708698 42.7261509,10.1708698 L44.2066392,10.1708698 C44.4516855,10.1708698 44.6604854,9.99091865 44.6987739,9.74703067 L46.1501628,0.45763161 C46.1787516,0.274586724 46.0383605,0.108557278 45.8550862,0.108557278 L44.1979604,0.108557278 C44.0514432,0.109072898 43.9258569,0.217352971 43.9028838,0.36378888 L43.9028838,0.36378888 Z"
                id="path12-3"
                fill="#009CDE"
              />
            </g>
            <g id="g24-4" transform="translate(11.551538, 0.025252)">
              <path
                d="M22.9962728,7.72672509 C23.3634361,5.36044005 22.9943301,3.75054215 21.7267426,2.29270652 C20.3319105,0.68771385 17.8122767,0 14.5884275,0 L5.22964869,0 C4.57108595,0 4.00965635,0.48463715 3.90669521,1.14193855 L0.0097133148,26.0997707 C-0.0670218721,26.5932373 0.309854742,27.0376515 0.803291134,27.0376515 L6.58077078,27.0376515 L6.18155354,29.5922976 C6.11453167,30.0229771 6.44478437,30.4124527 6.87702688,30.4124527 L11.7472829,30.4124527 C12.3232825,30.4124527 12.8138049,29.9886405 12.9041387,29.413747 L12.951734,29.1635801 L13.8686709,23.2880904 L13.9279221,22.9633639 C14.0182559,22.3884704 14.5087783,21.9636772 15.0847779,21.9636772 L15.8132765,21.9636772 C20.5310335,21.9636772 24.2250071,20.0290527 25.3041564,14.4292372 C25.7558255,12.0904215 25.522706,10.1371572 24.3299109,8.76467267 C23.9685756,8.35067089 23.5198205,8.00730449 22.9962728,7.72672509 L22.9962728,7.72672509"
                id="path18-6"
                fill="#009CDE"
              />
              <path
                d="M22.9962728,7.72672509 C23.3634361,5.36044005 22.9943301,3.75054215 21.7267426,2.29270652 C20.3319105,0.68771385 17.8122767,0 14.5884275,0 L5.22964869,0 C4.57108595,0 4.00965635,0.48463715 3.90669521,1.14193855 L0.0097133148,26.0997707 C-0.0670218721,26.5932373 0.309854742,27.0376515 0.803291134,27.0376515 L6.58077078,27.0376515 L8.03194001,17.7422325 L7.98725876,18.0345845 C8.0902199,17.376302 8.64582151,16.8926459 9.30535558,16.8926459 L12.0513097,16.8926459 C17.4441421,16.8926459 21.66652,14.6794042 22.9010823,8.28003552 C22.9370216,8.09069347 22.9681042,7.90821876 22.9962728,7.72672509"
                id="path20-7"
                fill="#012169"
              />
              <path
                d="M9.59189837,7.75811859 C9.65309225,7.3627567 9.9046671,7.03901124 10.2426905,6.87517641 C10.3961608,6.80061685 10.5680865,6.75941288 10.7487542,6.75941288 L18.0852208,6.75941288 C18.9545625,6.75941288 19.764653,6.81729465 20.5057789,6.93796341 C20.7175291,6.97230005 20.9234514,7.01154192 21.1235457,7.05667008 C21.32364,7.10081719 21.5179063,7.15085058 21.7063446,7.20677025 C21.8005637,7.23423956 21.8928402,7.26367097 21.9841454,7.29408342 C22.3483947,7.41671428 22.6873894,7.55994712 22.9982155,7.72672509 C23.3653788,5.36044005 22.9962728,3.75054215 21.7286852,2.29270652 C20.3319105,0.68771385 17.8122767,0 14.5884275,0 L5.22964869,0 C4.57108595,0 4.00965635,0.48463715 3.90669521,1.14193855 L0.0097133148,26.0997707 C-0.0670218721,26.5932373 0.309854742,27.0376515 0.803291134,27.0376515 L6.58077078,27.0376515 L8.03194001,17.7422325 L9.59189837,7.75811859 Z"
                id="path22-3"
                fill="#003087"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Paypal;
