import React from 'react';

function Ideal() {
  return (
    <svg
      version="1.1"
      viewBox="0 0 28 28"
      aria-hidden="true"
      className="svg-inline--bi bi-ideal bi-lg"
      data-test="icon-ideal"
      focusable="false"
    >
      <defs>
        <path id="ideal-a" d="M0 .001h27.934v24.353H0z" />
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(0 1.808)">
        <path
          fill="#FFF"
          d="M0 .001v24.356h16.06c3.508-.053 6.287-.924 8.26-2.602 2.398-2.04 3.614-5.263 3.614-9.576 0-2.062-.32-3.912-.951-5.497-.603-1.517-1.495-2.807-2.65-3.834C22.296 1.04 19.44.062 16.06.006L0 .001z"
        />
        <path fill="#0A0B09" d="M3.105 21.143h4.476V13.71H3.105z" />
        <mask id="ideal-b" fill="#fff">
          <use xlinkHref="#ideal-a" />
        </mask>
        <path
          fill="#0A0B09"
          d="M7.92 9.994a2.534 2.534 0 11-5.07 0 2.534 2.534 0 015.07 0"
          mask="url(#ideal-b)"
        />
        <path
          fill="#0A0B09"
          d="M15.756 1.619c3.112 0 5.707.843 7.502 2.438 2.03 1.804 3.058 4.536 3.058 8.122 0 7.106-3.454 10.56-10.56 10.56H1.618V1.62h14.138zM16.06 0H0v24.356h16.06v-.005c3.508-.048 6.287-.92 8.26-2.597 2.398-2.04 3.614-5.263 3.614-9.576 0-2.062-.32-3.912-.951-5.497-.603-1.517-1.495-2.807-2.65-3.834C22.296 1.04 19.44.062 16.06.006V.001z"
          mask="url(#ideal-b)"
        />
        <path
          fill="#B9215E"
          d="M15.314 21.151H9.636V3.588h5.678-.229c4.735 0 9.775 1.869 9.775 8.805 0 7.332-5.04 8.758-9.775 8.758h.23"
        />
        <path
          fill="#FFFFFE"
          d="M9.51 8.838v2.793h.638c.239 0 .411-.013.517-.04a.79.79 0 00.346-.175c.09-.083.166-.218.223-.407.058-.19.087-.447.087-.773s-.029-.576-.087-.75a.964.964 0 00-.242-.409.815.815 0 00-.396-.199c-.12-.026-.353-.04-.702-.04H9.51zm-.852-.716h1.559c.351 0 .62.027.804.08.247.073.46.203.636.39.177.186.312.414.404.684.092.27.138.603.138 1 0 .347-.043.647-.13.898a1.864 1.864 0 01-.452.747 1.5 1.5 0 01-.6.337c-.188.06-.44.09-.754.09H8.658V8.122z"
        />
        <path
          fill="#FFFFFE"
          d="M10.217 8.237c.339 0 .598.026.771.076.227.067.424.188.586.358.164.174.291.39.378.643.087.256.132.58.132.961 0 .334-.042.623-.124.862-.099.289-.242.525-.423.701-.135.131-.321.236-.555.31-.175.056-.418.084-.72.084H8.774V8.237h1.444zm-1.675-.23v4.456h1.72c.326 0 .592-.032.79-.094a1.62 1.62 0 00.646-.365c.208-.202.37-.469.48-.792.091-.263.137-.578.137-.937 0-.407-.049-.755-.145-1.036a1.97 1.97 0 00-.429-.727c-.19-.2-.421-.342-.688-.42-.197-.058-.47-.086-.836-.086H8.542z"
        />
        <path
          fill="#FFFFFE"
          d="M9.894 8.954c.421 0 .6.02.677.037.139.03.254.087.342.17.089.084.16.205.212.36.053.163.08.403.08.715 0 .313-.027.562-.081.739-.051.167-.116.287-.19.355a.68.68 0 01-.297.149 2.253 2.253 0 01-.489.037h-.523V8.954h.27zm-.5-.231v3.024h.754c.251 0 .43-.014.546-.044a.907.907 0 00.394-.202c.108-.097.192-.247.257-.459.062-.201.092-.465.092-.806s-.03-.599-.093-.787a1.082 1.082 0 00-.273-.456.933.933 0 00-.45-.228c-.129-.028-.367-.042-.727-.042h-.5zM13.163 12.348V8.122h3.129v.716h-2.277v.936h2.118v.716h-2.118v1.141h2.358v.717h-3.21"
        />
        <path
          fill="#FFFFFE"
          d="M16.176 8.237v.486H13.9V9.89h2.117v.485H13.9v1.372h2.357v.485h-2.978V8.237h2.897zm-3.128-.23v4.456h3.44v-.947h-2.357v-.91h2.117v-.947h-2.117v-.705h2.276v-.948h-3.36zm6.526 2.665l-.583-1.565-.57 1.565h1.153zm1.57 1.676h-.928l-.368-.96h-1.689l-.35.96h-.905l1.642-4.226h.909l1.69 4.226z"
        />
        <path
          fill="#FFFFFE"
          d="M19.376 8.237l1.598 3.995h-.678l-.37-.959h-1.848l-.35.96h-.655c.111-.287 1.5-3.859 1.552-3.996h.751zm-.909-.23l-1.732 4.456h1.155l.35-.959h1.528l.369.96h1.178l-1.782-4.458h-1.066z"
        />
        <path
          fill="#FFFFFE"
          d="M18.992 9.441l.415 1.116h-.822l.407-1.116zm-.737 1.347h1.485l-.75-2.016-.735 2.016zM21.857 12.348V8.156h.853v3.475h2.123v.717h-2.976"
        />
        <path
          fill="#FFFFFE"
          d="M22.594 8.272v3.475h2.123v.485h-2.744v-3.96h.621zm-.852-.231v4.422h3.206v-.947h-2.123V8.04h-1.083z"
        />
      </g>
    </svg>
  );
}

export default Ideal;
