import React, { useEffect, useState } from 'react';

import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { useRouter } from 'next/router';
import { useUser } from '../context/UserContext';

function AgeConfirmationModal() {
  const [open, setOpen] = useState(false);
  const { setTooYoung } = useUser();
  const { pathname, push } = useRouter();

  const routesWithoutModal = ['/checkout', '/dashboard', '/thanks'];

  useEffect(() => {
    if (pathname && !routesWithoutModal.find((rt) => pathname.includes(rt))) {
      if (window && window.localStorage) {
        const ageConfirmed = window.localStorage.getItem('ageConfirmed');
        if (!(ageConfirmed && ageConfirmed === 'true')) {
          setOpen(true);
        }
      }
    }
  }, [pathname, routesWithoutModal]);

  const ageConfirmationHandler = () => {
    window.localStorage.setItem('ageConfirmed', 'true');
    setTooYoung(false);
    setOpen(false);
  };

  return (
    <Modal isOpen={open} size="lg">
      <ModalHeader>Leeftijdscontrole</ModalHeader>
      <ModalBody className="py-5">
        <p>Om gebruik te maken van onze webshop dient u minimaal 18 jaar of ouder te zijn.</p>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-between">
        <Button color="success" onClick={ageConfirmationHandler}>
          Ja, ik ben 18 jaar of ouder
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default AgeConfirmationModal;
