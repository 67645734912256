/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import NProgress from 'nprogress';
import router from 'next/router';
import dayjs from 'dayjs';
import ContextProviders from 'shared/context';

import { pageview } from 'shared/utils/gtm';
import Layout from '../components/layout';

import 'typeface-proza-libre';
import '../scss/main.scss';
import 'react-image-lightbox/style.css';
import 'react-datepicker/dist/react-datepicker.css';

import 'dayjs/locale/nl-be';

dayjs.locale('nl-be');

function MyApp({ Component, pageProps }) {
  const handleRouteChangeStart = (url) => {
    console.log(`Loading: ${url}`);
    NProgress.start();
  };
  const handelRouterChangeComplete = (url) => {
    NProgress.done();
    pageview(url);
  };

  const handleRouterChangeError = () => NProgress.done();

  useEffect(() => {
    router.events.on('routeChangeStart', handleRouteChangeStart);
    router.events.on('routeChangeComplete', handelRouterChangeComplete);
    router.events.on('routeChangeError', handleRouterChangeError);
    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.off('routeChangeComplete', handelRouterChangeComplete);
      router.events.off('routeChangeError', handleRouterChangeError);
    };
  }, [router]);

  return (
    <ContextProviders>
      <Layout>
        <Component {...pageProps} />
      </Layout>
    </ContextProviders>
  );
}

export default MyApp;
