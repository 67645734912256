import React from 'react';

function DrankenLogo({ className, width, height, light }) {
  let drankenColor = '#1D1D1B';
  const beColor = '#ffffff';
  const circleColor = '#E50E34';
  if (light) drankenColor = '#ffffff';

  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 497 150"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="dranken_logo" transform="translate(-12.000000, -10.000000)" fillRule="nonzero">
          <g id="Group" transform="translate(12.000000, 9.999994)">
            <path
              d="M496.894003,74.9889023 C496.898043,105.326768 478.624464,132.679058 450.595929,144.288856 C422.567395,155.898653 390.305034,149.479031 368.855786,128.024057 C347.406538,106.569084 340.995528,74.3050104 352.612806,46.2795757 C364.230084,18.254141 391.587251,-0.0121369218 421.925115,0 C441.813407,0 460.887126,7.90059922 474.950272,21.9637452 C489.013418,36.0268913 496.914011,55.1006104 496.914011,74.9889023"
              id="Path"
              fill={circleColor}
            />
            <path
              d="M0,79.521858 C0,64.5120709 8.49553948,54.7757224 20.8235779,54.7757224 C26.8274928,54.7757224 31.4204876,57.1472687 34.5225103,61.4400678 L35.433104,61.4400678 L35.433104,34.9527969 L52.5142417,34.9527969 L52.5142417,103.067211 L35.433104,103.067211 L35.433104,97.5836351 L34.5225103,97.5836351 C31.4204876,101.876434 27.0376298,104.257987 20.9136367,104.257987 C8.40548076,104.257987 0,94.6617299 0,79.5018449 M36.1635803,79.5018449 C36.1635803,73.5679758 32.1609704,69.0950592 26.6673884,69.0950592 C21.1738063,69.0950592 17.1711964,73.5679758 17.1711964,79.5018449 C17.1711964,85.4357141 21.1037606,89.9186372 26.6673884,89.9186372 C32.2310161,89.9186372 36.1635803,85.4357141 36.1635803,79.5018449"
              id="Shape"
              fill={drankenColor}
            />
            <path
              d="M56.8970995,55.9664988 L73.9081916,55.9664988 L73.9081916,62.6308443 L74.908844,62.6308443 C76.6884743,58.1245459 81.0018969,55.1292232 85.8459755,55.035892 C87.6506904,55.0403057 89.4317101,55.4471029 91.0593749,56.2266684 L91.0593749,71.3865534 C88.4592276,70.2452736 85.670689,69.5939361 82.8340116,69.4653006 C77.6306188,69.4653006 73.9782372,73.5779823 73.9782372,79.6919689 L73.9782372,103.067211 L56.8970995,103.067211 L56.8970995,55.9664988 Z"
              id="Path"
              fill={drankenColor}
            />
            <path
              d="M89.7084941,90.6691265 C89.7084941,81.5331694 97.1033159,76.6599919 112.533377,74.5886413 L120.75874,73.5879888 L120.75874,71.4866186 C120.75874,68.0143546 118.477253,65.6428082 114.544688,65.6428082 C112.815743,65.5262469 111.120752,66.1623918 109.895427,67.3877162 C108.670103,68.6130406 108.033958,70.3080317 108.150519,72.0369775 L91.5997273,72.0369775 C91.5997273,61.5301265 100.6056,54.7757224 114.704793,54.7757224 C128.803986,54.7757224 137.899917,61.5301265 137.899917,72.1270362 L137.899917,103.067211 L120.728721,103.067211 L120.728721,97.4935764 L119.80812,97.4935764 C117.166398,101.876434 111.502705,104.157922 105.378712,104.157922 C96.0626373,104.157922 89.6784745,99.2347117 89.6784745,90.6491135 M120.698701,85.4357141 L120.698701,82.6138741 L113.113755,83.7045853 C108.110493,84.3450029 106.109188,86.2662556 106.109188,88.9980369 C106.109188,91.9199421 108.300617,93.8411948 112.053064,93.8411948 C117.166398,93.8411948 120.728721,90.278872 120.728721,85.4357141"
              id="Shape"
              fill={drankenColor}
            />
            <path
              d="M141.742423,55.9664988 L158.813554,55.9664988 L158.813554,62.9010204 L159.754167,62.9010204 C163.496607,57.4274514 167.419165,54.8958006 172.992799,54.8958006 C182.769174,54.8958006 189.613637,62.8409813 189.613637,73.7080671 L189.613637,103.067211 L172.512486,103.067211 L172.512486,76.8801354 C172.512486,72.3071537 169.960822,69.205131 165.938199,69.205131 C161.915576,69.205131 158.813554,72.4872711 158.813554,77.1503116 L158.813554,103.067211 L141.742423,103.067211 L141.742423,55.9664988 Z"
              id="Path"
              fill={drankenColor}
            />
            <polygon
              id="Path"
              fill={drankenColor}
              points="193.426123 34.9527969 210.50726 34.9527969 210.50726 73.2277539 211.417854 73.2277539 223.655834 55.9664988 243.748936 55.9664988 226.217504 78.6112642 244.019112 103.087224 223.745893 103.087224 211.417854 84.1848985 210.50726 84.1848985 210.50726 103.087224 193.426123 103.087224"
            />
            <path
              d="M237.444825,79.3417405 C237.444825,64.3319535 248.40197,54.7757224 264.022155,54.7757224 C279.64234,54.7757224 289.778949,64.4520318 289.778949,79.521858 C289.794397,81.0808364 289.700774,82.6389861 289.498767,84.1848985 L255.076322,84.1848985 C256.167033,89.2982326 259.639297,92.490314 264.752631,92.490314 C268.352336,92.6386054 271.694031,90.6272908 273.24817,87.3769799 L287.947755,90.0587285 C285.576209,98.9145029 275.939926,104.30802 263.842037,104.30802 C248.221852,104.30802 237.444825,94.5316451 237.444825,79.3717601 M272.517694,75.0489414 C272.057394,70.3959074 268.515084,66.5534019 263.66192,66.5534019 C258.808755,66.5534019 255.816804,69.8455486 254.886198,75.0489414 L272.517694,75.0489414 Z"
              id="Shape"
              fill={drankenColor}
            />
            <path
              d="M292.150496,55.9664988 L309.231633,55.9664988 L309.231633,62.9010204 L310.142227,62.9010204 C313.884667,57.4274514 317.817232,54.8958006 323.380859,54.8958006 C333.157234,54.8958006 340.001697,62.8409813 340.001697,73.7080671 L340.001697,103.067211 L322.930566,103.067211 L322.930566,76.8801354 C322.930566,72.3071537 320.368895,69.205131 316.356279,69.205131 C312.343663,69.205131 309.231633,72.4872711 309.231633,77.1503116 L309.231633,103.067211 L292.150496,103.067211 L292.150496,55.9664988 Z"
              id="Path"
              fill={drankenColor}
            />

            <path
              d="M355.231627,94.8618604 C355.231627,89.4783501 359.234237,85.6458511 364.817878,85.6458511 C370.401519,85.6458511 374.414135,89.4783501 374.414135,94.8618604 C374.414135,100.245371 370.411525,104.087876 364.817878,104.087876 C359.224231,104.087876 355.231627,100.255377 355.231627,94.8618604"
              id="Path"
              fill={beColor}
            />
            <path
              d="M400.090878,97.6036481 L399.180284,97.6036481 L399.180284,103.067211 L382.109153,103.067211 L382.109153,34.9527969 L399.180284,34.9527969 L399.180284,61.4400678 L400.090878,61.4400678 C403.202907,57.1472687 407.765882,54.7757224 413.799817,54.7757224 C426.127855,54.7757224 434.613388,64.5420905 434.613388,79.521858 C434.613388,94.6817429 426.217914,104.278 413.699751,104.278 C407.585765,104.278 403.202907,101.896447 400.090878,97.6036481 M417.452198,79.521858 C417.452198,73.5879888 413.519634,69.1150723 407.946,69.1150723 C402.372365,69.1150723 398.449808,73.5879888 398.449808,79.521858 C398.449808,85.4557271 402.452418,89.9386502 407.946,89.9386502 C413.439582,89.9386502 417.452198,85.4557271 417.452198,79.521858"
              id="Shape"
              fill={beColor}
            />
            <path
              d="M435.984282,79.3417405 C435.984282,64.3319535 446.941426,54.7757224 462.561612,54.7757224 C478.181797,54.7757224 488.318406,64.4520318 488.318406,79.521858 C488.333854,81.0808364 488.240231,82.6389861 488.038223,84.1848985 L453.615778,84.1848985 C454.70649,89.2982326 458.178754,92.490314 463.292088,92.490314 C466.891793,92.6386054 470.233488,90.6272908 471.787627,87.3769799 L486.487212,90.0587285 C484.115666,98.9145029 474.479382,104.30802 462.381494,104.30802 C446.761309,104.30802 435.984282,94.5316451 435.984282,79.3717601 M471.057151,75.0489414 C470.596851,70.3959074 467.054541,66.5534019 462.201377,66.5534019 C457.348212,66.5534019 454.346255,69.8455486 453.425654,75.0489414 L471.057151,75.0489414 Z"
              id="Shape"
              fill={beColor}
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

DrankenLogo.defaultProps = {
  className: '',
  width: '497px',
  height: '150px',
  light: false,
};

export default DrankenLogo;
