/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import {
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Button,
  ListGroup,
  ListGroupItem,
  Badge,
} from 'reactstrap';
import { MdMenu, MdExpandMore, MdPersonOutline, MdClose, MdChevronRight } from 'react-icons/md';
import { useCart } from 'shared/context/CartContext';
import { useUser } from 'shared/context/UserContext';
import Search from 'shared/components/Search';
import categories from '../../../utils/categories';

import DrankenLogo from '../../DrankenLogo';

function DrankenNav() {
  const { user, dashboardAccess, signout } = useUser();
  const { quantity } = useCart();
  const [cartAnimating, setCartAnimating] = useState(false);
  const [menuDropdownOpen, setMenuDropdownOpen] = useState(false);
  const [userDropDownOpen, setUserDropDownOpen] = useState(false);

  const toggleMenuDropdown = () => setMenuDropdownOpen((prev) => !prev);
  const toggleUserDropdown = () => setUserDropDownOpen((prev) => !prev);

  const topLinks = [
    { label: 'Bedrijven', href: '/bedrijven' },
    { label: 'Contact', href: `/contact` },
  ];

  useEffect(() => {
    if (quantity > 0) setCartAnimating(true);
    setTimeout(() => {
      setCartAnimating(false);
    }, 300);
  }, [quantity]);

  return (
    <header className="sticky-top" id="nav">
      <div className="bg-white w-100" style={{ zIndex: 200, top: 0 }}>
        <Row className="g-0">
          <Col
            xs={{ size: 2 }}
            md={{ size: 4, order: 3 }}
            lg={{ size: 3, order: 3 }}
            xl={{ size: 2, order: 3 }}
            className="bg-primary"
          >
            <Dropdown
              isOpen={menuDropdownOpen}
              toggle={toggleMenuDropdown}
              className="d-inline-block w-100 h-100"
              id="categories_dropdown"
            >
              <DropdownToggle
                className="w-100 h-100 rounded-0 px-md-3 px-xl-2 d-md-flex justify-content-between align-items-center"
                color="primary"
              >
                <span className="d-none d-md-inline-block text-uppercase">Alle producten</span>{' '}
                <div
                  style={{ width: 30, height: 30 }}
                  className="position-relative mx-auto mx-md-0"
                >
                  {menuDropdownOpen ? (
                    <MdClose
                      color="white"
                      size="28"
                      className="d-inline-block mw-100 position-relative"
                    />
                  ) : (
                    <>
                      <MdMenu color="white" size="30" className="d-inline-block d-md-none mw-100" />
                      <MdExpandMore
                        color="white"
                        size="30"
                        className="d-none d-md-inline-block mw-100"
                      />
                    </>
                  )}
                </div>
              </DropdownToggle>
              <DropdownMenu style={{ minWidth: '100%' }} className="py-0">
                <ListGroup flush className="bg-light">
                  {categories
                    .filter(({ inNav }) => !!inNav)
                    .map(({ name, displayName, subCategories, href, as }, catIndex) => (
                      <ListGroupItem
                        className="p-0 position-relative category"
                        key={`navlink-${catIndex}-${name}`}
                        onClick={toggleMenuDropdown}
                      >
                        <Link
                          href={href}
                          as={as}
                          className="d-block text-dark btn btn-light d-flex justify-content-between align-items-center"
                        >
                          <span>{displayName} </span>
                          <MdChevronRight color="black" />
                        </Link>
                        {subCategories && (
                          <ListGroup
                            flush
                            className="subcategories position-absolute d-none bg-light border"
                          >
                            {subCategories.map((subCat, subCatIndex) => (
                              <ListGroupItem
                                className="p-0"
                                key={`subcategory-${subCatIndex}-${subCat.name}`}
                              >
                                <Link
                                  href={subCat.href}
                                  as={subCat.as}
                                  className="d-block text-dark btn btn-light d-flex justify-content-between align-items-center"
                                >
                                  {subCat.name}
                                </Link>
                              </ListGroupItem>
                            ))}
                          </ListGroup>
                        )}
                      </ListGroupItem>
                    ))}
                </ListGroup>
              </DropdownMenu>
            </Dropdown>
          </Col>
          <Col
            md={{ size: 8, offset: 2, order: 2 }}
            lg={{ size: 10, offset: 0, order: 2 }}
            className="d-none d-md-flex align-items-center justify-content-end"
          >
            <ListGroup horizontal id="top-links" className="justify-content-end">
              {topLinks.map(({ label, href }, i) => (
                <ListGroupItem
                  key={`topLink_${i}`}
                  className="align-items-center text-muted d-none d-lg-flex"
                >
                  <Link href={href} className="text-muted text-decoration-none">
                    {label}
                  </Link>
                </ListGroupItem>
              ))}

              {/* <ListGroupItem className="px-0 border-0">
                <Link href="/contact" className="px-4 py-1 w-100 d-block text-center text-dark border-end">
                    Contact
                </Link>
              </ListGroupItem>
              <ListGroupItem className="px-0 border-0">
                <Link href="/dashboard/banners" className="px-4 py-1 w-100 d-block text-center text-dark border-end">
                    Klantenservice
                </Link>
              </ListGroupItem> */}
            </ListGroup>
          </Col>
          <Col
            xs={{ size: 7 }}
            md={{ size: 2, order: 1 }}
            className="d-flex justify-content-center align-items-center justify-content-md-start"
          >
            <Link href="/" className="px-2">
              <DrankenLogo
                width="160px"
                height="50px"
                className="mw-100 py-1 py-md-0 my-md-1 mx-md-5"
              />
            </Link>
          </Col>
          <Col
            xs={{ size: 3 }}
            md={{ size: 3, order: 5 }}
            lg={{ size: 3, order: 5 }}
            xl={{ size: 3, order: 5 }}
            // className="d-flex align-items-center justify-content-around"
          >
            <Row className="g-0 h-100">
              <Col xs="6" className="align-self-stretch">
                {user && user.firstName ? (
                  <Dropdown isOpen={userDropDownOpen} toggle={toggleUserDropdown} className="h-100">
                    <DropdownToggle
                      className="h-100 w-100 align-self-stretch p-1 d-flex justify-content-center align-items-center text-decoration-none border  rounded-0"
                      color="white"
                    >
                      <span className="d-none me-3 d-md-inline-block text-dark">
                        {user.firstName}
                      </span>{' '}
                      <MdPersonOutline className="mw-100" size="30" color="black" />
                    </DropdownToggle>
                    <DropdownMenu style={{ minWidth: '16rem' }}>
                      {dashboardAccess && (
                        <Link
                          href="/dashboard"
                          className="btn btn-white py-2 px-4 w-100 d-block text-start text-dark text-decoration-none"
                        >
                          Dashboard
                        </Link>
                      )}
                      <Link
                        href="/my-dranken/orders"
                        className="btn btn-white py-2 px-4 w-100 d-block text-start text-dark text-decoration-none"
                      >
                        Orders
                      </Link>
                      <Link
                        href="/my-dranken/account"
                        className="btn btn-white py-2 px-4 w-100 d-block text-start text-dark text-decoration-none"
                      >
                        Account
                      </Link>
                      <Button
                        color="white"
                        onClick={signout}
                        className="w-100 py-2 px-4 text-start text-dark text-decoration-none"
                      >
                        Uitloggen
                      </Button>
                    </DropdownMenu>
                  </Dropdown>
                ) : (
                  <Link
                    href="/login"
                    className="align-self-stretch h-100 p-1 d-flex justify-content-center align-items-center text-decoration-none border"
                  >
                    <div>
                      <span className="d-none me-2 d-lg-inline-block text-dark">Inloggen</span>{' '}
                      <MdPersonOutline className="d-inline-block mw-100" size="30" color="black" />
                    </div>
                  </Link>
                )}
              </Col>
              <Col xs="6" className="align-self-stretch">
                <Link
                  href="/cart"
                  id="navCart"
                  className="h-100 d-flex align-items-center justify-content-center bg-success"
                >
                  <div
                    className="position-relative mx-auto d-block"
                    style={{ width: 28, height: 28 }}
                  >
                    <svg style={{ width: 28, height: 28 }} className="mw-100" viewBox="0 0 24 24">
                      <path
                        fill="#fff"
                        d="M17,18A2,2 0 0,1 19,20A2,2 0 0,1 17,22C15.89,22 15,21.1 15,20C15,18.89 15.89,18 17,18M1,2H4.27L5.21,4H20A1,1 0 0,1 21,5C21,5.17 20.95,5.34 20.88,5.5L17.3,11.97C16.96,12.58 16.3,13 15.55,13H8.1L7.2,14.63L7.17,14.75A0.25,0.25 0 0,0 7.42,15H19V17H7C5.89,17 5,16.1 5,15C5,14.65 5.09,14.32 5.24,14.04L6.6,11.59L3,4H1V2M7,18A2,2 0 0,1 9,20A2,2 0 0,1 7,22C5.89,22 5,21.1 5,20C5,18.89 5.89,18 7,18M16,11L18.78,6H6.14L8.5,11H16Z"
                      />
                    </svg>
                    <Badge
                      pill
                      className={`bg-white position-absolute ${cartAnimating ? 'animate' : ''}`}
                    >
                      <span className="text-muted">{quantity}</span>
                    </Badge>
                  </div>
                </Link>
              </Col>
            </Row>

            {/* <NavbarText>Simple Text</NavbarText> */}
          </Col>
          <Col
            xs={{ size: 12 }}
            md={{ size: 5, order: 4 }}
            lg={{ size: 6, order: 4 }}
            xl={{ size: 7, order: 4 }}
            className="d-flex align-items-center border"
          >
            <Search placeholder="Waar ben je naar op zoek?" className="w-100" />
          </Col>
        </Row>
      </div>
    </header>
  );
}

export default DrankenNav;
