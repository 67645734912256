import axios from 'axios';

let baseURL = 'http://localhost:8888';
// let baseURL = 'https://api.shopdeal.be';

if (process.env.NODE_ENV !== 'development') {
  switch (process.env.NEXT_PUBLIC_APP) {
    case 'dranken':
      baseURL = 'https://api.dranken.be';
      break;
    case 'shopdeal':
      baseURL = 'https://api.shopdeal.be';
      break;
    default:
      break;
  }
}

const axiosInstance = axios.create({
  baseURL,
  withCredentials: true,
});

export default axiosInstance;
