import React from 'react';

function Bpost({ light }: {light?: boolean}) {
  const textColor = light ? '#333' : '#fff';
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="100.879 296.897 130.214 71.346" height="20">
      <defs>
        <path
          d="M173.354 303.512c-8.533 18.611-28.445 37.67-44.483 42.521-16.075 4.869-22.102-6.346-13.482-25.012l-8.501 2.574c-4.047 8.754-5.979 16.436-6.008 22.662v.266c.061 13.863 9.684 20.387 26.574 15.262 24.479-7.426 54.86-36.523 67.816-64.889l-21.916 6.616z"
          id="a"
        />
      </defs>
      <clipPath id="c">
        <use overflow="visible" xlinkHref="#a" />
      </clipPath>
      <linearGradient
        gradientTransform="rotate(-35 2610.439 -3048.211) scale(109.6536)"
        y2="-15.428"
        x2="-11.734"
        y1="-15.428"
        x1="-12.734"
        gradientUnits="userSpaceOnUse"
        id="b"
      >
        <stop offset="0" stopColor="#a40d11" />
        <stop offset=".026" stopColor="#a60e13" />
        <stop offset=".345" stopColor="#c51521" />
        <stop offset=".598" stopColor="#d81829" />
        <stop offset=".75" stopColor="#df192b" />
        <stop offset="1" stopColor="#df192b" />
      </linearGradient>
      <path
        fill="url(#b)"
        clipPath="url(#c)"
        d="M164.215 252.549l63.95 91.328-96.232 67.385-63.949-91.332z"
      />
      <g>
        <defs>
          <path
            d="M119.228 319.858c-4.392 9.52-1.326 15.23 6.861 12.756 8.191-2.473 18.355-12.189 22.717-21.688l-29.578 8.932z"
            id="d"
          />
        </defs>
        <clipPath id="f">
          <use overflow="visible" xlinkHref="#d" />
        </clipPath>
        <linearGradient
          gradientTransform="scale(36.676) rotate(-35 55.122 -38.179)"
          y2="-29.245"
          x2="-13.468"
          y1="-29.245"
          x1="-14.469"
          gradientUnits="userSpaceOnUse"
          id="e"
        >
          <stop offset="0" stopColor="#a40d11" />
          <stop offset=".026" stopColor="#a60e13" />
          <stop offset=".345" stopColor="#c51521" />
          <stop offset=".598" stopColor="#d81829" />
          <stop offset=".75" stopColor="#df192b" />
          <stop offset="1" stopColor="#df192b" />
        </linearGradient>
        <path
          fill="url(#e)"
          clipPath="url(#f)"
          d="M137.63 294.967l22.527 32.174-34.144 23.91-22.528-32.174z"
        />
      </g>
      <path
        d="M186.386 363.298c-.802 0-1.689-.141-2.16-.277v5.223h-3.792v-17.166c1.117-.496 3.23-.92 5.483-.92 4.84 0 7.471 2.459 7.471 6.576 0 3.925-2.416 6.564-7.002 6.564m-.417-10.639c-.688 0-1.41.107-1.742.256v7.688c.297.129 1.104.238 1.773.238 2.356 0 3.586-1.691 3.586-4.107 0-2.573-1.286-4.075-3.617-4.075m16.098 10.785c-4.352 0-6.774-2.854-6.774-6.662 0-3.799 2.424-6.625 6.774-6.625 4.359 0 6.778 2.826 6.778 6.625 0 3.809-2.418 6.662-6.778 6.662m0-10.765c-1.999 0-2.989 1.535-2.989 4.086 0 2.543.99 4.055 2.989 4.055 2.018 0 3.007-1.512 3.007-4.055-.001-2.549-.99-4.086-3.007-4.086m13.061 10.765c-1.883 0-3.734-.424-4.854-.926l1.229-2.447c.739.395 2.422.822 3.502.822 1.376 0 2.251-.543 2.251-1.395 0-.939-.966-1.346-2.311-1.752-1.896-.582-4.273-1.342-4.273-3.844 0-2.242 2.002-3.748 5.493-3.748 1.93 0 3.481.395 4.62.92l-1.167 2.195c-.628-.34-1.979-.734-2.979-.734-1.325 0-2.118.566-2.118 1.32 0 .973.982 1.354 2.288 1.754 1.971.609 4.395 1.318 4.395 3.889-.002 2.39-2.093 3.946-6.076 3.946m13.695-.099c-2.9 0-4.268-1.344-4.268-4.012v-6.492h-1.925v-2.406h1.925v-2.836l3.725-.912v3.748h2.813v2.406h-2.813v6.283c0 1.037.76 1.393 1.73 1.393.396 0 .821-.098 1.081-.191V363c-.448.181-1.363.345-2.268.345m-50.812-6.612c0 4.125-2.637 6.588-7.479 6.588-2.258 0-4.367-.424-5.478-.928v-17.154h3.779v5.217c.471-.143 1.364-.281 2.155-.281 4.599 0 7.023 2.642 7.023 6.558m-3.813 0c0-2.41-1.223-4.104-3.586-4.104-.672 0-1.466.115-1.776.232v7.691c.333.146 1.06.266 1.744.266 2.347.001 3.618-1.51 3.618-4.085"
        fill={textColor}
      />
    </svg>
  );
}

export default Bpost;
