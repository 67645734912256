import React from 'react';
import AgeConfirmationModal from 'shared/components/AgeConfirmationModal';
import CookieConfirmation from 'shared/components/CookieConfirmation';
import Head from 'next/head';
import Nav from './navigation/Nav';
import Footer from './Footer';

function Layout({ children }) {
  return (
    <>
      <Head>
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#e50e34" />
        <meta name="msapplication-TileColor" content="#e50e34" />
        <meta name="theme-color" content="#ffffff" />
        <meta
          name="google-site-verification"
          content="5_kUsZcUjzQk9MT4rZTaMfLDDNAlCtO2ifrtetI3Crs"
        />
      </Head>
      <AgeConfirmationModal />
      {/* relative container for sticky nav */}
      <div className="position-relative">
        <Nav />
        <main>{children}</main>
        <CookieConfirmation />
        <Footer />
      </div>
    </>
  );
}

export default Layout;
