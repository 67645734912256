import React from 'react';

function Kbc() {
  return (
    <svg
      version="1.1"
      viewBox="0 0 48 48"
      aria-hidden="true"
      className="svg-inline--bi bi-kbc bi-lg"
      data-test="icon-kbc"
      focusable="false"
    >
      <g fill="#00a0e4">
        <ellipse cx="24.28" cy="8.41" rx="8.29" ry="8.41" />
        <path d="M30.39 15.22a10.8 10.8 0 01-14.61 1.16C6.73 17.35 0 18.71 0 18.71v3.72h48v-7.67s-8.22-.05-17.61.46z" />
      </g>
      <g fill="#003664">
        <path d="M5.7 33.3v4.53H0v-12h5.7v5.37l3.35-5.42h6.84l-5 6.23 4.73 5.82h-6.5L5.74 33.3zM48 37.34a17.16 17.16 0 01-4.7.66c-5.64 0-9.78-1.63-9.78-6.36 0-4.28 4-6.14 9.5-6.14a17 17 0 015 .67v4.14a6.87 6.87 0 00-3.86-1.12c-2.09 0-4.18 1-4.18 2.56s2.08 2.57 4.18 2.57A7.16 7.16 0 0048 33.21zM16.86 25.78h11.53c3 0 4 1.18 4 3 0 2.06-1.81 3-3.67 3.06 1.91.15 3.77.37 3.77 2.8 0 1.59-1 3.14-4.27 3.14H16.86zm5.7 9.6h3c1.11 0 1.49-.46 1.49-1.16s-.4-1.22-1.5-1.22h-3zm0-4.49h2.84c1.1 0 1.53-.45 1.53-1.14s-.43-1.19-1.45-1.19h-2.92z" />
      </g>
    </svg>
  );
}

export default Kbc;
