import React from 'react';

function Belfius() {
  return (
    <svg
      version="1.1"
      viewBox="0 0 48 48"
      aria-hidden="true"
      className="svg-inline--bi bi-belfius bi-lg"
      data-test="icon-belfius"
      focusable="false"
    >
      <path
        fill="#c30045"
        d="M0 35.81A8.19 8.19 0 008.18 44H44V8.18A8.18 8.18 0 0035.81 0H8.18A8.18 8.18 0 000 8.18z"
      />
      <g fill="#fff">
        <path d="M10.1 15.86a3.06 3.06 0 013.13-3.13h17.53a3.13 3.13 0 110 6.26H13.23a3.06 3.06 0 01-3.13-3.13zM10.1 28.14A3.06 3.06 0 0113.23 25h17.53a3.13 3.13 0 110 6.26H13.23a3.06 3.06 0 01-3.13-3.12z" />
      </g>
    </svg>
  );
}

export default Belfius;
