import React, { createContext, useContext, useState, useEffect } from 'react';

const deliveryMethods = ['delivery', 'pick-up'] as const;
type DeliveryMethod = (typeof deliveryMethods)[number];

type CartItem = Product & {quantity: number};

interface CartContextType {
  cart: CartItem[],
  addItem: (item: Product, qty?: number) => void,
  removeItem: (item: Product, qty?: any) => void,
  quantity: number,
  deliveryMethod: DeliveryMethod,
  setDeliveryMethod: React.Dispatch<React.SetStateAction<DeliveryMethod>>,
  orderValue: number,
  shippingCost: number,
  deleteCart: () => void,
  discountCode: string,
  setDiscountCode: React.Dispatch<React.SetStateAction<string>>,
}

const CartContext = createContext<CartContextType | undefined>(undefined);

export function CartContextProvider({ children }) {
  const [cart, setCart] = useState<CartItem[]>([]);
  const [modified, setModified] = useState(false);
  const [orderValue, setOrderValue] = useState(0);
  const [shippingCost, setshippingCost] = useState(0);
  const [discountCode, setDiscountCode] = useState('');
  const [deliveryMethod, setDeliveryMethod] = useState<DeliveryMethod>('delivery');
  const [quantity, setQuantity] = useState(0); // amount of bottles / items

  // calculate the ordervalue and orderquantity on cart changes
  useEffect(() => {
    const calculateOrderValue = () => {
      const orderv = cart.reduce(
        (accumulator, currentValue) =>
          accumulator + currentValue.quantity * currentValue.pricing.sellingPrice,
        0
      );
      setOrderValue(orderv);
    };

    const calculateQuantity = () => {
      const qty = cart.reduce((acc, curr) => acc + curr.quantity, 0);
      setQuantity(qty);
    };

    calculateOrderValue();
    calculateQuantity();
    if (!modified) return;
    window.localStorage.setItem('cart', JSON.stringify(cart));
  }, [modified, cart]);

  // calculate shipping cost based of orderValue & deliveryMethod
  useEffect(() => {
    const calculateShipping = () => {
      if (deliveryMethod === 'pick-up') {
        setshippingCost(0);
      } else {
        setshippingCost(595);
      }
    };
    calculateShipping();
  }, [deliveryMethod, orderValue]);

  // get items from localstoraage on mount
  useEffect(() => {
    if (window && window.localStorage) {
      const cartFromStorage = window.localStorage.getItem('cart');
      if (cartFromStorage) {
        setCart(JSON.parse(cartFromStorage));
      }
    }
  }, []);

  function addItem(item: Product, qty = 1) {
    setModified(true);

    const updateIndex = cart.findIndex((cartItem) => cartItem._id === item._id);
    if (updateIndex === -1) return setCart((prevCart) => [...prevCart, { ...item, quantity: qty }]);

    setCart((prevCart) =>
      prevCart.map((cartItem, index) =>
        index === updateIndex ? { ...cartItem, quantity: cartItem.quantity + qty } : cartItem
      )
    );
  }

  function removeItem(item: Product, qty?: number) {
    setModified(true);
    if (qty) {
      return setCart((prevCart) =>
        prevCart
          .map((cartItem) =>
            cartItem._id === item._id
              ? { ...cartItem, quantity: cartItem.quantity - qty }
              : cartItem
          )
          .filter((cartItem) => !(cartItem.quantity <= 0))
      );
    }
    setCart((prevCart) => prevCart.filter((cartItem) => cartItem._id !== item._id));
  }

  return (
    <CartContext.Provider
      value={{
        cart,
        addItem,
        removeItem,
        quantity,
        deliveryMethod,
        setDeliveryMethod,
        orderValue,
        shippingCost,
        deleteCart: () => setCart([]),
        discountCode,
        setDiscountCode,
      }}
    >
      {children}
    </CartContext.Provider>
  );
}

function useCart() {
  const context = useContext(CartContext);
  if (context === undefined) throw new Error('useCart must be used within a CartProvider');

  return context;
}

export { useCart, CartContext };
export default CartContextProvider;
