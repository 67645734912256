import React from 'react';

function Dpd({ light }: {light?: boolean }) {
  const textColor = light ? '#333' : '#fff';
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 324.543 153.5 67.457">
      <path
        fill="#D50033"
        d="M28.802 326.397c.581-.266 1.153-.753 1.836-.55.587.252 1.148.563 1.706.875 6.628 3.606 13.252 7.219 19.88 10.825 1.5.807 2.979 1.656 4.5 2.426-2.694 1.665-5.471 3.199-8.198 4.815-3.308 1.921-6.611 3.852-9.919 5.772-.479.287-.492.903-.434 1.396.075.641-.157 1.447.409 1.923.569.391 1.17.743 1.788 1.052.322.172.666.003.95-.16 5.134-2.986 10.265-5.978 15.399-8.965.474-.271.95-.537 1.4-.848.053 9.51.001 19.022.025 28.532.03.76-.426 1.478-1.053 1.88-8.399 4.952-16.804 9.895-25.198 14.853-.765.59-1.881.823-2.734.289a27332.066 27332.066 0 0 0-25.638-15.108c-.634-.358-1.03-1.06-1.175-1.751-.015-9.565.01-19.133-.013-28.698 8.317 4.774 16.584 9.641 24.884 14.444.277.146.603.354.549.722.005 7.123-.001 14.247.002 21.372-.032.461.34.816.724 1.003.506.257.981.583 1.512.79.636.155 1.155-.332 1.688-.59.478-.25 1.126-.552 1.095-1.191 0-8.019 0-16.035-.002-24.052.033-.437-.287-.787-.649-.976-9.44-5.477-18.862-10.989-28.309-16.454 8.306-4.57 16.653-9.076 24.975-13.626z"
      />
      <g fill={textColor}>
        <path d="M92.368 340.033c2.12.002 4.238-.002 6.355.002-.023 11.215-.013 22.431-.003 33.646-1.357.372-2.752.568-4.141.78-3.395.341-6.894.643-10.233-.25-2.621-.656-5.106-2.123-6.671-4.364-1.834-2.594-2.352-5.928-2.01-9.035.287-2.802 1.467-5.576 3.569-7.495 1.974-1.866 4.648-2.864 7.329-3.094 1.955-.08 3.987-.104 5.827.661-.04-3.616 0-7.234-.022-10.851m-7.779 16.8c-1.347.893-2.208 2.405-2.438 3.99-.352 2.081-.04 4.398 1.287 6.099 1.064 1.364 2.754 2.116 4.441 2.334 1.497.142 3.027.111 4.506-.179-.04-4.191.023-8.386-.03-12.577-.81-.41-1.724-.554-2.615-.679-1.756-.2-3.652.01-5.151 1.012zM144.427 340.031c2.12.007 4.239-.005 6.359.007-.03 11.215-.014 22.43-.008 33.644-3.362.84-6.845 1.146-10.305 1.104-3.063-.104-6.226-.859-8.676-2.782-2.073-1.586-3.421-4.001-3.897-6.548-.542-2.89-.313-5.967.898-8.662a10.855 10.855 0 0 1 4.657-5.044c1.539-.863 3.271-1.336 5.019-1.515 2.004-.102 4.084-.12 5.977.642-.051-3.615-.008-7.231-.024-10.846m-8.584 17.464c-1.27 1.242-1.757 3.073-1.777 4.806.018 1.59.378 3.241 1.36 4.528 1.031 1.38 2.692 2.161 4.366 2.41 1.546.172 3.123.123 4.65-.161-.032-4.199-.007-8.398-.012-12.6-1.352-.561-2.834-.768-4.29-.716-1.565.083-3.176.592-4.297 1.733zM102.323 352.094c3.38-1.286 6.999-1.976 10.619-1.905 2.877 0 5.827.726 8.188 2.416 2.105 1.494 3.583 3.813 4.104 6.336.392 1.525.347 3.118.27 4.679-.237 2.924-1.312 5.89-3.497 7.925-2.624 2.472-6.371 3.321-9.886 3.239.008-1.839.021-3.679-.009-5.518 1.753-.072 3.59-.489 4.931-1.689 1.524-1.342 2.142-3.46 2.05-5.439.077-1.946-.765-3.989-2.41-5.096-1.233-.867-2.771-1.166-4.254-1.17-1.252.021-2.512.157-3.707.548.005 9.112-.023 18.225.015 27.338-2.145.006-4.288-.004-6.431.005.025-10.557-.01-21.114.017-31.669z" />
      </g>
    </svg>
  );
}

export default Dpd;
