/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { ListGroupItem, Form, Input } from 'reactstrap';
import { IoMdSearch } from 'react-icons/io';
import { useRouter } from 'next/router';
import { debounce } from 'lodash';
import { useCombobox } from 'downshift';
// import Image from 'next/image';
import Spinner from 'reactstrap/lib/Spinner';
import Link from 'next/link';
import axiosInstance from 'shared/utils/axiosInstance';

function Search({ placeholder, className }) {
  const [searchResults, setSearchResults] = useState([]);
  const [searchFocussed, setSearchfocussed] = useState(false);
  const [searching, setSearching] = useState(false);
  const [error, setError] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const { push, events: routerEvents } = useRouter();

  const updateQuery = async () => {
    setSearching(true);
    setError(false);
    try {
      const { data } = await axiosInstance('/product', {
        params: { term: searchTerm, app: process.env.NEXT_PUBLIC_APP, limit: 30 },
      });
      setSearchResults(data.products || []);
    } catch (error) {
      setError(true);
      setSearchResults([]);
    }
    setSearching(false);
  };

  const delayedQuery = debounce(updateQuery, 250);

  const {
    isOpen,
    getItemProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    reset,
  } = useCombobox({
    items: searchResults,
    onSelectedItemChange: ({ selectedItem }) => {
      if (selectedItem) {
        push(`/[categoryName]/[slug]/`, `/${selectedItem.category}/${selectedItem.slug}/`);
      }
    },
    itemToString: ({ name, details }) => {
      let base = name;
      if (details && details.year) base += ` ${details.year}`;
      if (details && details.volume) base += ` ${details.volume}`;
      return base;
    },
  });

  function searchReset() {
    setSearchfocussed(false);
    setSearchTerm('');
    setSearchResults([]);
    reset();
    delayedQuery.cancel();
  }

  useEffect(() => {
    if (searchTerm) {
      delayedQuery(searchTerm);
    } else searchReset();
  }, [searchTerm]);

  useEffect(() => {
    searchReset();
    routerEvents.on('routeChangeComplete', searchReset);
    return () => routerEvents.off('routeChangeComplete', searchReset);
  }, []);

  const searchFormSubmitHandler = (e) => {
    e.preventDefault();
    if (!searchTerm) return;
    push({ pathname: '/search', query: { query: searchTerm } });
  };

  return (
    <div {...getComboboxProps({})} id="search" className={className}>
      <Form className="flex-grow-1" id="text-search" inline onSubmit={searchFormSubmitHandler}>
        <div
          className={`border-bottom border-${
            searchFocussed ? 'primary' : 'muted'
          } input-group w-100`}
        >
          <div className="input-group-prepend">
            <button
              onClick={searchFormSubmitHandler}
              type="button"
              className="input-group-text border-0 bg-white unstyled"
              id="basic-addon1"
            >
              <IoMdSearch size="20" />
            </button>
          </div>
          <Input
            {...getInputProps({
              type: 'search',
              placeholder,
              id: 'search',
              className: 'border-0 border-bottom',
              value: searchTerm,
              onChange: ({ target }: any) => setSearchTerm(target.value),
              onBlur: () => setSearchfocussed(false),
              onFocus: () => setSearchfocussed(true),
            })}
          />
          {searching && (
            <Spinner
              size="sm"
              id="searchSpinner"
              color="muted"
              className="position-absolute right"
            />
          )}
        </div>
      </Form>
      <ul className="list-group position-absolute" {...getMenuProps()}>
        {!isOpen ? null : (
          <>
            {searchResults &&
              searchResults.map((item, index) => {
                const { name, image, _id } = item;
                return (
                  <ListGroupItem
                    {...getItemProps({ item, index })}
                    key={`searchResult_${_id}`}
                    className={highlightedIndex === index ? `bg-light highlighted` : ''}
                  >
                    <Link
                      href="/[categoryName]/[slug]/"
                      as={`/${item.category}/${item.slug}/`}
                      className="d-flex text-dark align-items-center">

                      <img
                        width={50}
                        height={50}
                        src={image.src.small}
                        alt={name}
                        className="me-2"
                      />
                      {name}

                    </Link>
                  </ListGroupItem>
                );
              })}
            {searchTerm && searchTerm.length > 1 && searchResults.length === 0 && !searching && (
              <ListGroupItem>Geen resultaten gevonden voor "{searchTerm}"</ListGroupItem>
            )}
          </>
        )}
      </ul>
    </div>
  );
}

Search.defaultProps = {
  className: '',
};

export default Search;
